* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  background-color: #474069;
  color: #e9e8e4;
  overflow-x: hidden;

  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

h1, h4 {
  margin: .5em;
}

.Header {
  top: 0;
  position: relative;
  height: 100px;
  width: 90%;
  left:5%;
 }

 .Navbar {
  top: 30px;
  position: absolute;
  text-align: right;
  width: 100%;
 }
 
 .App {
   text-align: center;
   position: relative;
 }
 
 /* Now set the height of your Footer equal to the padding that you left for it in your #root.  To make it stay at the bottom, you will set it to be absolutely positioned.  If Main grows larger, your Footer will move down to remain at the bottom of the page. */ 
 .Footer {
   height: 20px;
   width: 96%;
   position: absolute;
   bottom: 0;
   font-size: 12px;
   text-align: right;
   color: #727a9e;
 }

 .Page {
  height: 500px;
}